$remove-color: #888 !default;
$remove-hover-color: #555 !default;
$remove-width: 20px !default;

$selection-color: #444 !default;

$border-color: $border-color !default;
$border-radius: 0 !default;

$focus-border-color: #5897fb !default;

$container-height: 28px !default;

$selection-bg-top-color: white !default;
$selection-bg-bottom-color: #eeeeee !default;

$container-placeholder-color: #999 !default;

$container-focus-border-color: blue !default;

$selection-opened-bg-top-color: $selection-bg-bottom-color !default;
$selection-opened-bg-bottom-color: $selection-bg-top-color !default;

$dropdown-z-index: 1 !default;

$dropdown-bg-color: $selection-bg-top-color !default;

$results-max-height: 200px !default;
$results-nested-padding: 20px !default;

$results-choice-bg-hover-color: #3875d7 !default;
$results-choice-fg-hover-color: white !default;

$results-choice-fg-unselectable-color: grey !default;