legend {
  font-size: $font-size-base;
}

.blue {
  color: $blue;
}

html, body {
  min-height: 100%;
}

body {
  background-color: $gray-light;
}

a {
  &:hover, &:focus {
    text-decoration: none;
    color: inherit;
  }
}


/* BOOTSTRAP STYLES */


.basic-mb {
  margin-bottom: $grid-gutter-width;
}

.color-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
  border: 1px solid #000;

  &.light {
    border-color: $border-dark-color;
    background-color: $light;
  }

  &.warning {
    border-color: $warning;
    background-color: $warning;
  }

  &.danger {
    border-color: $danger;
    background-color: $danger;
  }
}

.tag-container {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 40px;

  .tag {
    border: 1px solid $blue;
    display: flex;
    align-items: stretch;
    margin-left: 5px;
    margin-right: 5px;

    > div {
      padding: 5px 10px;
    }

    .delete {
      position: relative;
      border-left: 1px solid $blue;
      width: 35px;
      cursor: pointer;

      &:before, &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        background-color: $blue;
        top: 15px;
        left: 7px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.nav-pills > li > a {
  border-radius: 0px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: $blue;
}

.alert {
  border-radius: 0px;
  font-weight: 400;
}

//.alert-success {
//  color: #fff;
//  background-color: #63b94c;
//  border-color: #64b154;
//}

.panel-group .panel {
  border-radius: 0;
}

.panel-group .panel, .panel-default > .panel-heading, .panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-color: #bdbdbd;
}

.panel-default > .panel-heading {
  background-color: rgb(249, 249, 249);
}

/* BOOTSTRAP END STYLES */


.help-block {
  clear: left;
  margin-left: 15px;
}

#cms-body {
  width: 100%;
  min-height: calc(100vh - #{$top-height});


}


#container-main {
  width: calc(100% - 255px);
  padding: 30px;


  #work-container-header {
    margin-bottom: 40px;

    h1 {
      margin: 0;
    }
  }

}

.line {
  width: 100%;
  height: 1px;
  background-color: $border-dark-color;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
}

#white-content, .white-content {
  background-color: #fff;

  #work-container {
    border-right: 1px solid $border-dark-color;

    h5 {
      &:first-child {
        margin-bottom: 35px;
      }
    }
  }

  #right-sidebar {
    width: 350px;
    @include media-breakpoint-up(xxl){
      min-width: 350px;
    }
  }

  #right-sidebar, #work-container {
    padding: 30px;

    h5 {
      margin-bottom: 20px;
    }
  }


}

.box-shadow {
  box-shadow: 0 0 3px 0 rgba($gray-base, 0.1);
  border: 1px solid transparent;

  &.danger {
    border-color: $red;
    box-shadow: 0 0 16px -2px rgba($red, 0.5);
  }
}

.white-box-with-shadow {
  background-color: $white;
  padding: 30px 30px;
}

ul.pagination {
  margin-top: 40px;
}

ul.cms-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    min-height: 50px;

    > .level_content {
      border-bottom: 1px solid #eceeed;

      > div {
        padding-top: 20px;
        padding-bottom: 20px;

        &.more-dates {
          padding-top: 10px;
          padding-bottom: 9px;
        }
      }
    }
  }
}

.move {
  cursor: pointer;
}

ul.cms-list li.header {
  border-bottom: 2px solid #eceeed;
}

ul.cms-list li .title {
  position: relative;
}

ul.cms-list li .title .hidden-bar {
  position: absolute;
  bottom: -18px;
  display: none;
}

ul.cms-list .level_content:hover .hidden-bar {
  display: block;
}

ul.cms-list li .title .hidden-bar a {
  font-size: 13px;
}

.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-top: 2px;
  display: inline-block;

  &.circle-color-0 {
    background-color: #c1bfbf;
  }

  &.circle-color-1 {
    background-color: #6cc573;
  }
}

.arrow-right {
  width: 0px;
  height: 0px;
  border: 5px solid transparent;
  border-left-color: $blue;
  line-height: 1;
  font-size: 0;
  vertical-align: super;
}

.cms-section {
  margin: 30px 0px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ededed;

  &:last-child, .no-border {
    border-bottom: 0px;
  }
}

#save_buttons {
  border-top: 1px solid #ededed;
  margin-top: 30px;
  padding-top: 30px;
  margin-bottom: -15px;

  .btn {
    &:last-child {
      margin-left: 10px;
    }
  }
}

#dashboard-welcome {
  padding: 50px 50px;

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 28px;
  }

  p, a {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  a {
    color: $blue;
  }
}

.input {
  //height: 38px;
  border: 1px solid #bdbdbd;
  padding: 0px 16px;
  font-style: italic;
}

.textarea {
  border: 1px solid #bdbdbd;
  padding: 8px 16px;
  font-style: italic;
  min-height: 100px;
}

.mce-tinymce {
  margin: 0px 15px !important;
}

.cms-checkbox-content {
  margin: 0px 3px;
  display: inline-block;

  .form-check {
    padding-left: 0;
  }

  .form-check-input {
    margin-left: 0;
    margin-top: $font-size-base;
  }
}

.cms-checkbox {
  //display: none;
  opacity: 0;

  &:checked + .form-check-label {
    @extend .btn-primary;
  }
}

label.cms-label {
  min-width: 80px;
  text-align: center;
  padding: 4px 6px;
  border: 1px solid $blue;
}

.cms-checkbox:checked + label.cms-label {
  background-color: $blue;
  color: #fff;
}

/*toggle_widget*/

.toggle_widget_container {
  .cms-checkbox {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.toggle_widget {
  .toggle_widget_container {
    border: 1px solid #e0e0e0;
    box-shadow: inset 0px 1px 6px 2px rgba(0, 0, 0, 0.06);
  }

  .choice_label {
    padding: 6px 25px;
    font-weight: 400;
    margin: 0;
  }

  .cms-checkbox:checked + .choice_label {
    color: #fff;
    border-radius: 3px;
  }

  .cms-checkbox:checked + .choice_label.toggle_color_1 {
    background-color: #63b94c;
    border-bottom: 3px solid #428d32;
  }

  .cms-checkbox:checked + .choice_label.toggle_color_0 {
    background-color: #d24b51;
    border-bottom: 3px solid #b22128;
  }
}

.circle-separator {
  width: 4px;
  height: 4px;
  background-color: #404145;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 3px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#cms-tabs {
  .cms-tab {
    height: 52px;
    line-height: 52px;
    padding-left: 20px;
    padding-right: 40px;
    border-top: 2px solid #6f7376;
    background-color: rgba(255, 255, 255, 0.4);
    font-weight: 400;
    font-size: 15px;
    margin-right: 8px;
  }
}

#cms-tabs .cms-tab.active {
  background-color: #fff;
  border-color: $blue;
}

.image_from_entity_image_container input {
  display: none;
}

.image_from_entity_image_container input:checked ~ .image_from_entity {
  border-color: $blue;
}

.image_from_entity {
  height: 0;
  padding-bottom: 100%;
  display: block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px solid transparent;
}

#photo-container {
  @media (min-width: 1200px) and (max-width: 1400px) {
    .col-lg-2 {
      width: calc(4 / 12 * 100%);
    }
  }
  display: flex;
  flex-wrap: wrap;
}

.galery-album {
  margin-bottom: 40px;

  .image {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid rgba(234, 234, 234, 0.39);
  }

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .actions {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

#dropContainer {
  width: 100%;
  height: 100px;
  border: 1px solid #efefef;
  background-color: rgba(234, 234, 234, 0.39);
  display: flex;
  align-items: center;
  justify-content: center;
}



#ajax-search-container {
  width: 300px;
}

#ajax-search-container input[type=search] {
  width: 100%;
}

#ajax-search-result {
  position: relative;
}

#ajax-result-container {
  position: absolute;
  top: 10px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ededed;
  padding: 10px 20px;
}


.form-group .mce-tinymce {
  margin: 0 !important;
}

#category-tree {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #ededed;
  padding: 10px;
  list-style: none;

  ul {
    list-style: none;
    display: none;

    &.active {
      display: block;
    }
  }
}

.images_widget_image_container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 30px;

  input {
    opacity: 0;
    position: absolute;
    //z-index: 100;
  }

  .images_widget_image {
    border: 1px solid #ededed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
  }

  input:checked + .images_widget_image {
    border: 3px solid $blue;
  }
}

.attribute_widget_attributes {
  margin: 20px -3px;

  .tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

.right-sidebar-note {
  position: relative;
  background-color: #f7f8fa;
  padding: 15px;
  margin-bottom: 10px;

  &.done {
    background-color: $green;
  }

  .flag {
    position: absolute;
    top: 18px;
    right: 0px;
    border: 1px solid $brand-success;
    border-right: 0px;
    background-color: #fff;
    padding: 5px 10px;
  }

  .row-section {
    margin-bottom: 10px;
  }
}

.card {
  border-bottom: 1px solid $border-color !important;
  //.card-header {
  //  margin-bottom: 0 !important;
  //}

  &:not(.collection-child-actions-container) {
    position: relative;
    padding: 15px 0;
    margin-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    &.done {
      background-color: $green;
    }

    .flag {
      position: absolute;
      top: 18px;
      right: 0px;
      border: 1px solid $brand-success;
      border-right: 0px;
      background-color: #fff;
      padding: 5px 10px;
    }

    .row-section {
      margin-bottom: 10px;
    }
  }
}

.title-with-separator {
  position: relative;
  padding-right: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  display: inline-block;
  border-bottom: 1px solid $blue;
}

#filters-panel {
}

.information-section {
  .section {
    margin-bottom: 20px;
  }
}

.cms-head-control-button {
  margin-left: 5px;
}

.collection-type-form-container {
  > .form-group {
    position: relative;

    > .row > div {
      display: flex;

      .input {
        color: $text-color;
        //@include placeholder(#bdbdbd);

        &:not(:first-of-type) {
          margin-left: $grid-gutter-width / 4;
        }

        &:not(:last-of-type) {
          margin-right: $grid-gutter-width / 4;
        }
      }
    }

    div[class$="collection-actions"] {
      position: absolute;
      top: 0;
      left: calc(100% + #{$grid-gutter-width});
      white-space: nowrap;

      a {
        line-height: 36px;
        padding-top: 0;
        padding-bottom: 0;

        &:not(:first-of-type) {
          margin-left: 2px;
        }

        &:not(:last-of-type) {
          margin-right: 2px;
        }
      }
    }
  }
}

.input-group-addon {
  padding-left: 10px;
  padding-right: 10px;

  .jcon-clock {
    font-size: 21px;
  }
}

.core-admin-bundle-tecollection-type {
  > .form-row {
    &:not(:first-of-type) {
      padding-top: 5px;
    }

    &:not(:last-of-type) {
      padding-bottom: 5px;
    }
  }
}

.collection-child-actions-container {
  &.card {
    div[class$="-collection-actions"] {
      position: absolute;
      top: $card-spacer-y;
      right: $card-spacer-x;
    }

    .card-header {
      cursor: move;
    }
  }
}

div[class$="-collection-actions"] {
  @extend .btn-group;
  @include make-col(3);

  > .btn-outline-secondary {
    border-color: $input-border-color;
  }

  display: flex;

  .collection-remove {
    order: 4;
  }

  .collection-add {
    order: 1;
  }

  .collection-up {
    order: 2;
  }

  .collection-down {
    order: 3;
  }
}