//########## BS VARIABLES ##########
$black: #2d2b2b;
$white: #fff;
$light: #f6f6f9;
$dark: #343a40;

$blue: #1ca7f9;
$green: #def9de;
$green-dark: #a3dca3;
$red: #f25567;

$gray-base: #1d1b1b;
$gray-darker: #313538;
$gray-dark: #404145;
$gray: #f3f4f5;
$gray-light: #f3f4f5;
$gray-lighter: #fff;

$primary: $blue;
$secondary: #929599;
$success: $green;
$info: $blue;
$warning: #f6b034;
$danger: $red;


$text-color: $gray-darker;
$font-color: $gray-darker;
$text-color-light: $secondary;
$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
