.note {
  display: flex;
  border: 1px solid $gray;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;

  &.header {
    margin-bottom: 0;
  }

  &.inactive {
    opacity: 0.6;
    background-color: $gray-light;
  }

  &.selected {
    border-color: $secondary;
    background-color: $light;
  }

  .note-col {
    padding-left: 15px;
    padding-right: 15px;

    p {
      margin-bottom: 0.6em;

      b {
        font-weight: $font-weight-bold;
      }
    }
  }

  .date {
    width: 200px;

    .priority {
      display: inline-block;
      border: 1px solid $brand-primary;
      color: $brand-primary;
      padding: 3px 6px;
      margin-top: 5px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }

  .target {
    width: 150px;
  }

  .description {
    width: 100%;
  }

  .last-status {
    width: 190px;
  }

  .actions {
    width: 120px;
  }
}

#note-filter {
  margin-bottom: 15px;
}

#note-filter-list {
  margin-bottom: 30px;
  padding: 20px 0;

  #target {
    margin-bottom: 20px;
  }

  #target, #priority {
    display: flex;
    align-items: stretch;

    > div {
      width: 100%;
      display: flex;
      align-items: flex-start;

      .radio {
        margin-left: 10px;
        margin-top: 0;
      }
    }
  }

  .note-choice {
    display: flex;
    align-items: center;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    input:checked + label {
      background-color: $info;
      color: $white;
      border-color: $info;
    }

    label {
      font-size: 13px;
      cursor: pointer;
      border: 1px solid $secondary;

      &:hover {
        background-color: $white;
      }

      i {
        font-size: 20px;
        margin-right: 10px;

        &.note-1 {
          @extend .jcon-informacje !optional;
        }

        &.note-2 {
          @extend .jcon-email !optional;
        }

        &.note-3 {
          @extend .jcon-telefon !optional;
        }

        &.note-4 {
          @extend .jcon-spotkanie !optional;
        }

        &.note-5 {
          @extend .jcon-oferta !optional;
        }

        &.note-6 {
          @extend .jcon-decyzja !optional;
        }

        &.note-7 {
          @extend .jcon-umowy !optional;
        }

        &.note-8 {
          @extend .jcon-firmy !optional;
        }
      }
    }


  }
}

#note-description {
  margin-bottom: 30px;
  background-color: $gray-light;
  padding: 15px;


}

//.note-task{
//  .contact-persons{
//    margin-top: 20px;
//  }
//}
