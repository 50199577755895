/* The container must be positioned relative: */
.te-select {
  position: relative;
  &.standard{
    width: 33%;
  }
  .w-200px{

  }
}

.te-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  border-bottom: 1px solid $border-dark-color;
  padding-right: 20px;

  &:after {
    content: $jcon-down;
    color: $info;
    right: 7px;
    position: absolute;
    top: 32%;
    @extend .jcon;
  }
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  //border-color: transparent transparent #fff transparent;
  //top: 7px;
  transform: rotate(180deg);
}

/* style the items (options), including the selected item: */
.select-items div, .select-selected {
  line-height: 36px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: $white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border: 1px solid $border-dark-color;
  border-top: none;
  padding: 5px;

  > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  //background-color: rgba(0, 0, 0, 0.1);
  color: $info;
}