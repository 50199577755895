.tinymce {
  @import "tinymce_colors";
  img {
    max-width: 100%;
  }

  h5 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 18px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      padding-left: 15px;
      background: url("/images/li-item.png");
      background-position: 0 12px;
      background-repeat: no-repeat;
    }
  }

  .light {
    font-weight: 300;
  }

  .regular {
    font-weight: 400;
  }

  .bold, strong, b {
    font-weight: 600;
  }

  .bolder {
    font-weight: 700;
  }

}