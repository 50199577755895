@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z3y0gu');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z3y0gu#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?z3y0gu') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?z3y0gu') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?z3y0gu##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.jcon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jcon-oko {
  &:before {
    content: $jcon-oko; 
  }
}
.jcon-decyzja {
  &:before {
    content: $jcon-decyzja; 
  }
}
.jcon-oferta {
  &:before {
    content: $jcon-oferta; 
  }
}
.jcon-spotkanie {
  &:before {
    content: $jcon-spotkanie; 
  }
}
.jcon-email {
  &:before {
    content: $jcon-email; 
  }
}
.jcon-firmy {
  &:before {
    content: $jcon-firmy; 
  }
}
.jcon-informacje {
  &:before {
    content: $jcon-informacje; 
  }
}
.jcon-telefon {
  &:before {
    content: $jcon-telefon; 
  }
}
.jcon-umowy {
  &:before {
    content: $jcon-umowy; 
  }
}
.jcon-cirlce-plus {
  &:before {
    content: $jcon-cirlce-plus; 
  }
}
.jcon-domains {
  &:before {
    content: $jcon-domains; 
  }
}
.jcon-password {
  &:before {
    content: $jcon-password; 
  }
}
.jcon-domeny {
  &:before {
    content: $jcon-domeny; 
  }
}
.jcon-home {
  &:before {
    content: $jcon-home; 
  }
}
.jcon-prezent {
  &:before {
    content: $jcon-prezent; 
  }
}
.jcon-profile-male {
  &:before {
    content: $jcon-profile-male; 
  }
}
.jcon-profile-female {
  &:before {
    content: $jcon-profile-female; 
  }
}
.jcon-alarm {
  &:before {
    content: $jcon-alarm; 
  }
}
.jcon-todo {
  &:before {
    content: $jcon-todo; 
  }
}
.jcon-man {
  &:before {
    content: $jcon-man; 
  }
}
.jcon-firms {
  &:before {
    content: $jcon-firms; 
  }
}
.jcon-trade_firms {
  &:before {
    content: $jcon-trade_firms; 
  }
}
.jcon-group {
  &:before {
    content: $jcon-group; 
  }
}
.jcon-kalendarz {
  &:before {
    content: $jcon-kalendarz; 
  }
}
.jcon-women {
  &:before {
    content: $jcon-women; 
  }
}
.jcon-menu {
  &:before {
    content: $jcon-menu; 
  }
}
.jcon-services {
  &:before {
    content: $jcon-services; 
  }
}
.jcon-contracts {
  &:before {
    content: $jcon-contracts; 
  }
}
.jcon-users {
  &:before {
    content: $jcon-users; 
  }
}
.jcon-close {
  &:before {
    content: $jcon-close; 
  }
}
.jcon-tasks {
  &:before {
    content: $jcon-tasks; 
  }
}
.jcon-trade_tasks {
  &:before {
    content: $jcon-trade_tasks; 
  }
}
.jcon-zlecone {
  &:before {
    content: $jcon-zlecone; 
  }
}
.jcon-plus {
  &:before {
    content: $jcon-plus; 
  }
}
.jcon-jellinek .path1 {
  &:before {
    content: $jcon-jellinek-path1;  
    color: rgb(20, 80, 116);
  }
}
.jcon-jellinek .path2 {
  &:before {
    content: $jcon-jellinek-path2;  
    margin-left: -4.4892578125em;  
    color: rgb(20, 80, 116);
  }
}
.jcon-jellinek .path3 {
  &:before {
    content: $jcon-jellinek-path3;  
    margin-left: -4.4892578125em;  
    color: rgb(188, 32, 39);
  }
}
.jcon-jellinek .path4 {
  &:before {
    content: $jcon-jellinek-path4;  
    margin-left: -4.4892578125em;  
    color: rgb(188, 32, 39);
  }
}
.jcon-jellinek .path5 {
  &:before {
    content: $jcon-jellinek-path5;  
    margin-left: -4.4892578125em;  
    color: rgb(63, 65, 68);
  }
}
.jcon-jellinek .path6 {
  &:before {
    content: $jcon-jellinek-path6;  
    margin-left: -4.4892578125em;  
    color: rgb(63, 65, 68);
  }
}
.jcon-jellinek .path7 {
  &:before {
    content: $jcon-jellinek-path7;  
    margin-left: -4.4892578125em;  
    color: rgb(63, 65, 68);
  }
}
.jcon-jellinek .path8 {
  &:before {
    content: $jcon-jellinek-path8;  
    margin-left: -4.4892578125em;  
    color: rgb(63, 65, 68);
  }
}
.jcon-jellinek .path9 {
  &:before {
    content: $jcon-jellinek-path9;  
    margin-left: -4.4892578125em;  
    color: rgb(63, 65, 68);
  }
}
.jcon-jellinek .path10 {
  &:before {
    content: $jcon-jellinek-path10;  
    margin-left: -4.4892578125em;  
    color: rgb(63, 65, 68);
  }
}
.jcon-document {
  &:before {
    content: $jcon-document; 
  }
}
.jcon-documents {
  &:before {
    content: $jcon-documents; 
  }
}
.jcon-search {
  &:before {
    content: $jcon-search; 
  }
}
.jcon-newspaper {
  &:before {
    content: $jcon-newspaper; 
  }
}
.jcon-calendar {
  &:before {
    content: $jcon-calendar; 
  }
}
.jcon-picture {
  &:before {
    content: $jcon-picture; 
  }
}
.jcon-pictures {
  &:before {
    content: $jcon-pictures; 
  }
}
.jcon-video {
  &:before {
    content: $jcon-video; 
  }
}
.jcon-camera {
  &:before {
    content: $jcon-camera; 
  }
}
.jcon-printer {
  &:before {
    content: $jcon-printer; 
  }
}
.jcon-briefcase {
  &:before {
    content: $jcon-briefcase; 
  }
}
.jcon-wallet {
  &:before {
    content: $jcon-wallet; 
  }
}
.jcon-gift {
  &:before {
    content: $jcon-gift; 
  }
}
.jcon-bargraph {
  &:before {
    content: $jcon-bargraph; 
  }
}
.jcon-expand {
  &:before {
    content: $jcon-expand; 
  }
}
.jcon-focus {
  &:before {
    content: $jcon-focus; 
  }
}
.jcon-edit {
  &:before {
    content: $jcon-edit; 
  }
}
.jcon-ribbon {
  &:before {
    content: $jcon-ribbon; 
  }
}
.jcon-hourglass {
  &:before {
    content: $jcon-hourglass; 
  }
}
.jcon-lock {
  &:before {
    content: $jcon-lock; 
  }
}
.jcon-megaphone {
  &:before {
    content: $jcon-megaphone; 
  }
}
.jcon-trophy {
  &:before {
    content: $jcon-trophy; 
  }
}
.jcon-flag {
  &:before {
    content: $jcon-flag; 
  }
}
.jcon-map {
  &:before {
    content: $jcon-map; 
  }
}
.jcon-puzzle {
  &:before {
    content: $jcon-puzzle; 
  }
}
.jcon-basket {
  &:before {
    content: $jcon-basket; 
  }
}
.jcon-envelope {
  &:before {
    content: $jcon-envelope; 
  }
}
.jcon-streetsign {
  &:before {
    content: $jcon-streetsign; 
  }
}
.jcon-gears {
  &:before {
    content: $jcon-gears; 
  }
}
.jcon-key {
  &:before {
    content: $jcon-key; 
  }
}
.jcon-paperclip {
  &:before {
    content: $jcon-paperclip; 
  }
}
.jcon-attachment {
  &:before {
    content: $jcon-attachment; 
  }
}
.jcon-pricetags {
  &:before {
    content: $jcon-pricetags; 
  }
}
.jcon-lightbulb {
  &:before {
    content: $jcon-lightbulb; 
  }
}
.jcon-layers {
  &:before {
    content: $jcon-layers; 
  }
}
.jcon-pencil {
  &:before {
    content: $jcon-pencil; 
  }
}
.jcon-tools {
  &:before {
    content: $jcon-tools; 
  }
}
.jcon-tools-2 {
  &:before {
    content: $jcon-tools-2; 
  }
}
.jcon-magnifying-glass {
  &:before {
    content: $jcon-magnifying-glass; 
  }
}
.jcon-linegraph {
  &:before {
    content: $jcon-linegraph; 
  }
}
.jcon-mic {
  &:before {
    content: $jcon-mic; 
  }
}
.jcon-caution {
  &:before {
    content: $jcon-caution; 
  }
}
.jcon-recycle {
  &:before {
    content: $jcon-recycle; 
  }
}
.jcon-genius {
  &:before {
    content: $jcon-genius; 
  }
}
.jcon-map-pin {
  &:before {
    content: $jcon-map-pin; 
  }
}
.jcon-chat {
  &:before {
    content: $jcon-chat; 
  }
}
.jcon-heart {
  &:before {
    content: $jcon-heart; 
  }
}
.jcon-target {
  &:before {
    content: $jcon-target; 
  }
}
.jcon-piechart {
  &:before {
    content: $jcon-piechart; 
  }
}
.jcon-speedometer {
  &:before {
    content: $jcon-speedometer; 
  }
}
.jcon-global {
  &:before {
    content: $jcon-global; 
  }
}
.jcon-compass {
  &:before {
    content: $jcon-compass; 
  }
}
.jcon-clock {
  &:before {
    content: $jcon-clock; 
  }
}
.jcon-quote {
  &:before {
    content: $jcon-quote; 
  }
}
.jcon-scope {
  &:before {
    content: $jcon-scope; 
  }
}
.jcon-alarmclock {
  &:before {
    content: $jcon-alarmclock; 
  }
}
.jcon-refresh {
  &:before {
    content: $jcon-refresh; 
  }
}
.jcon-happy {
  &:before {
    content: $jcon-happy; 
  }
}
.jcon-sad {
  &:before {
    content: $jcon-sad; 
  }
}
.jcon-down {
  &:before {
    content: $jcon-down; 
  }
}
.jcon-up {
  &:before {
    content: $jcon-up; 
  }
}
.jcon-right {
  &:before {
    content: $jcon-right; 
  }
}
.jcon-left {
  &:before {
    content: $jcon-left; 
  }
}
.jcon-arrow-down {
  &:before {
    content: $jcon-arrow-down; 
  }
}
.jcon-arrow-up {
  &:before {
    content: $jcon-arrow-up; 
  }
}
.jcon-arrow-right {
  &:before {
    content: $jcon-arrow-right; 
  }
}
.jcon-arrow-left {
  &:before {
    content: $jcon-arrow-left; 
  }
}

