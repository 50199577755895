$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1441px
);


$screen-lg: 1367px;

$input-border-radius: 0px;
$btn-border-radius: 0;
$border-radius: 1rem;

$btn-font-size: 0.8rem;
$btn-padding-y: 0.475rem;
$btn-padding-x: 0.85rem;

$label-default-bg: $gray-dark;

$border-radius: 5px;
$border-color: $gray;
$border-dark-color: #e4e5e5;

$input-placeholder-color: lighten($gray, 5%);

$card-border-radius: 0;
$card-inner-border-radius: 0;
$card-cap-bg: $gray-lighter;

$modal-lg: 1200px;

$tooltip-font-size: .75rem;
$tooltip-padding-x: 0.3rem;
$tooltip-padding-y: 0.05rem;

$brand-primary: #449dd7;
$brand-success: $green-dark; //#a3dca3
$brand-danger: #bd2027;
$brand-warning: #997e0b;

$state-success-bg: $green;
$state-info-bg: rgba($blue, 0.5);
$state-danger-bg: #ffd3d2;

// badges
$badge-border-radius: 0.2rem;
$badge-font-size: 80%;
$badge-font-weight: $font-weight-light;
$badge-padding-y: 0.4em;
$badge-padding-x: 0.7em;

// pagination
$pagination-color: $text-color;

//datepicker
$bs-datetimepicker-alternate-color: $secondary;

$nav-tabs-border-color: $border-color;
$nav-tabs-link-hover-border-color: $border-color;
$nav-tabs-link-active-border-color: $border-color;
