@if global-variable-exists(font-families) {
  @each $font-family, $fonts in $font-families {
    $formats: eot woff2 woff ttf;
    @if (map-has-key($fonts, formats)) {
      $formats: map-get($fonts, formats);
      $fonts: map-remove($fonts, formats);
    }

    @each $font-weight, $font in $fonts {
      @include font-face(
              $font-family: $font-family,
              $file-path: $font-folder + $font,
              $weight: $font-weight,
              $file-formats: $formats
      );
    }
  }
}

strong {
  font-weight: $font-weight-bold;
}

b {
  font-weight: $font-weight-regular;
}

div, li, p, article, section, td, a {
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  color: $text-color;
  line-height: $line-height-base;
  word-wrap: break-word;
}

p{
  margin-bottom: $line-height-base + em;
}

@for $h from 1 through 6 {
  h#{$h}, .h#{$h}{
    @include te-header-variant($h);
  }
}