#dashboard-boxes {
  margin-bottom: 50px;

  .box {
    height: 82px;
    background-color: $white;
    padding-left: 27px;
    padding-right: 27px;

    &.danger {
      //border: 1px solid $red;
      //box-shadow: 0 0 16px -2px rgba($red, 0.5);

      i {
        color: $red;
      }

      .count {
        color: $red;
      }
    }

    i {
      font-size: 29px;
      margin-right: 16px;
    }

    span {
      line-height: 1.2rem;
    }

    .count {
      font-size: 26px;
      margin-left: 16px;
    }
  }
}

#dashboard-services {
  .service {
    height: 52px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    .service-name {
      color: $text-color-light;
    }
  }
}