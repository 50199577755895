$font-color: #383e41;
$gray-font-color: #828486;
$red-font-color: #eb3a63;
$crimson: #a0282c;
$border-color: #e4e4e4;
$gray-light: #c3c1c1;
$light: #f0f1f0;
$lighter: #f4f4f4;
$blue: #3a6989;
$navy-blue: #0062ab;
$violet: #5274b9;
$orange: #f5862d;
$green: #1e7e3d;

$colors: (
        "dark": $font-color,
        "gray": $gray-font-color,
        "red": $red-font-color,
        "white": #fff,
        "blue": $blue,
        "orange": $orange,
        "navy-blue":$navy-blue,
        "violet":$violet,
        "crimson": $crimson,
        "gray-light": $gray-light,
        "lighter":$light,
        "green": $green,
        "primary": $blue,
);

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
  .border-#{$name} {
    border-color: $color !important;
  }
}
