.breadcrumb {
  background-color: transparent;
  padding: 0;
  display: flex;

  li, a {
    //font-size: 13px;
    //font-weight: 400;
  }

  > li + li:before {
    //color: #333;
  }

  > li {
    margin-right: 10px;
    display: flex;
    align-items: center;
    > span {
      color: $text-color-light;
      text-decoration: underline;
    }
  }
  .separator{
    width: 5px;
    height: 5px;
    background-color: $text-color-light;
    display: block;
    margin-left: 10px;
  }
}