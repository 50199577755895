.label {
  font-weight: 400;
  &.label-default {
    background-color: transparent;
    border: 1px solid $gray;
    color: $font-color;
    &:hover{
      color: $font-color;
      background-color: transparent;
      border: 1px solid $gray-dark;
    }
  }
}