#offer-templates-list {
  display: flex;
  align-items: stretch;

  > div {

  }

  .sheet-preview {
    border: 1px solid $border-color;
    padding: 10px;
    position: relative;

    .sheet-badge {
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 10;
      font-size: 11px;
      background-color: #fff;
      border: 1px solid transparent;
      padding: 5px 7px;
      text-transform: uppercase;

      &.offer {
        background-color: #eb3a63;
        color: $white;
      }

      &.report {
        background-color: #5274b9;
        color: $white;
      }

      &.inactive {
        color: $white;
        background-color: $danger;
        border-color: $danger;
      }
    }

    img {
      opacity: 0.8;
    }


    &.is-added {
      img {
        opacity: 1;
      }
    }

    .sheet-panel {
      .title {
        height: 50px;
        overflow: hidden;
        display: flex;
        align-items: center;
        font-weight: $font-weight-semibold;
        line-height: 1.3em;
      }
    }
  }
}

#added-items {
  .added-item {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba($gray-dark, 0.4);

    .move {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        position: relative;
        width: 2px;
        height: 20px;
        background-color: $text-color;

        &:after, &:before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border: 5px solid transparent;
          left: -4px;
        }

        &:after {
          top: -5px;
          border-bottom-color: $text-color;
        }

        &:before {
          bottom: -5px;
          border-top-color: $text-color;
        }
      }
    }
  }
}
