@function font-source-declaration(
  $font-family,
  $file-path,
  $file-formats) {
  $src: ();
  $formats-map: (
          eot:   "#{$file-path}.eot?#iefix" format("embedded-opentype"),
          woff2: "#{$file-path}.woff2" format("woff2"),
          woff:  "#{$file-path}.woff" format("woff"),
          ttf:   "#{$file-path}.ttf" format("truetype"),
          svg:   "#{$file-path}.svg##{$font-family}" format("svg")
  );

  @each $key, $values in $formats-map {
    $ddd: index($file-formats, $key);
    @if index($file-formats, $key) {
      $file-path: nth($values, 1);
      $font-format: nth($values, 2);

      $src: append($src, url($file-path) $font-format, comma);
    }
  }

  @return $src;
}