#left-sidebar {
  width: 250px;
  min-height: calc(100vh - #{$top-height});
  background-color: $white;
  border-right: 1px solid #ededed;

  #welcome {
    padding: 50px 20px;
    font-size: 18px;
    text-align: center;

    > div {
      flex-direction: column;
    }

    #gender {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 1px solid $border-dark-color;
      margin-bottom: 30px;
      overflow: hidden;

      i {
        font-size: 77px;
        color: $border-color;
      }
    }

    #user-name-main {
      font-size: 15px;
    }
  }


  #menu {
    padding-top: 15px;
    padding-bottom: 35px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    > ul {
      padding: 0px;

      > li {
        padding-left: 25px;
        border-left: 3px solid transparent;

        &:hover {
          border-left-color: $red;

          a {
            color: $red;
          }
        }

        &.current {
          a {
            color: $blue;
          }
        }

        a, span {
          //padding: 16px 20px;
          display: flex;
          align-items: center;
          height: 33px;
          line-height: 33px;
          margin-top: 17px;
          margin-bottom: 17px;

          i {
            width: 22px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  #secondary-menu {
    padding-top: 40px;
    padding-left: 45px;

    a, span {
      display: flex;
      align-items: center;
      height: 33px;
      line-height: 33px;

      i {
        width: 22px;
        margin-right: 15px;
      }
    }
  }
}
