.btn {
  border-radius: 0;
  //font-weight: 400;
  &.btn-default {
    background-color: transparent;
    border: 1px solid $gray;
    color: $font-color;
    &:hover{
      color: $font-color;
      background-color: transparent;
      border: 1px solid #bfbfbf;
    }
  }
}

.btn-primary {
  border-color: $blue;
  background-color: $blue;
}

.btn-black {
  background-color: #404145;
  color: #fff;
}

.btn-black:hover {
  color: #fff;
}