.birthday-box {
  padding: 15px 10px;

  &.is-birthday {
    .day, .username, .icon {
      color: $danger;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-dark-color;
  }

  .date {
    width: 34px;
    margin-right: 36px;

    .day {
      font-size: 26px;
      line-height: 31px;
    }

    .month {
      font-size: 13px;
      text-transform: uppercase;
      color: $text-color-light;
    }
  }

  .icon {

  }

  .username {

  }
}