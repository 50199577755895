@mixin te-header-variant($heading-type){
  position: relative;
  margin-top: 0;

  @if(map-has-key($heading-font-size, $heading-type)){
    font-size: map-get($heading-font-size, $heading-type);
  } @else {
    font-size: map-get($heading-font-size, others);
  }

  @if(map-has-key($heading-line-height, $heading-type)){
    line-height: map-get($heading-line-height, $heading-type);
  } @else {
    line-height: map-get($heading-line-height, others);
  }

  @if(map-has-key($heading-font-family, $heading-type)){
    font-family: map-get($heading-font-family, $heading-type);
  } @else {
    font-family: map-get($heading-font-family, others);
  }

  @if(map-has-key($heading-color, $heading-type)){
    color: map-get($heading-color, $heading-type);
  } @else {
    color: map-get($heading-color, others);
  }

  @if(map-has-key($heading-font-weight, $heading-type)){
    font-weight: map-get($heading-font-weight, $heading-type);
  } @else {
    font-weight: map-get($heading-font-weight, others);
  }

  @if(map-has-key($heading-margin-bottom, $heading-type)){
    margin-bottom: map-get($heading-margin-bottom, $heading-type);
  } @else {
    margin-bottom: map-get($heading-margin-bottom, others);
  }

  @if(map-has-key($heading-text-transform, $heading-type)){
    text-transform: map-get($heading-text-transform, $heading-type);
  } @else {
    text-transform: map-get($heading-text-transform, others);
  }
}

@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
  $file-formats: eot woff2 woff ttf) {

  @font-face {
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;
    src: font-source-declaration(
                    $font-family,
                    $file-path,
                    $file-formats
    );
  }
}
