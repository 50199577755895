#top {
  height: $top-height;
  border-bottom: 2px solid $border-color;
  background-color: $white;

  #logo {
    img {
      height: 35px;
      margin-bottom: 6px;
    }

    #separator {
      height: 22px;
      width: 1px;
      background-color: $text-color;
      margin-left: 27px;
      margin-right: 27px;
    }

    #logo-text {
      font-size: 14px;
    }
  }

  #top-sidebar-alerts {
    a {
      &:not(:last-child) {
        margin-right: 37px;
      }

      i {
        margin-right: 10px;

        &.jcon-menu {
          font-size: 13px;
        }

        &.jcon-man {
          font-size: 16px;
        }

        &.jcon-close {
          font-size: 11px;
        }
      }
    }

    //
    //  #tasks-count, #meetings-count {
    //    display: none;
    //    margin-right: 20px;
    //    align-items: center;
    //    padding-right: 20px;
    //    border-right: 1px solid $gray;
    //
    //    &.active {
    //      display: flex;
    //    }
    //  }
    //
    //  .circle {
    //    width: 20px;
    //    height: 20px;
    //    line-height: 20px;
    //    border-radius: 50%;
    //    background-color: $brand-danger;
    //    color: $gray-lighter;
    //    text-align: center;
    //    margin-left: 10px;
    //  }
  }
}