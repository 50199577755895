#comments {
  .comment {
    padding: 15px 15px;
    //padding-top: 15px;
    //padding-bottom: 15px;
    &:nth-child(odd) {
      background-color: $gray-light;
    }
    &.status-2 {
      background-color: rgba($blue, 0.4);
    }
    &.status-3 {
      background-color: $state-success-bg;
    }
    &.status-4 {
      background-color: $state-danger-bg;
    }
  }
}
