$icomoon-font-family: "Jcons" !default;
$icomoon-font-path: "fonts" !default;

$jcon-oko: "\e933";
$jcon-decyzja: "\e92b";
$jcon-oferta: "\e92c";
$jcon-spotkanie: "\e92d";
$jcon-email: "\e92e";
$jcon-firmy: "\e92f";
$jcon-informacje: "\e930";
$jcon-telefon: "\e931";
$jcon-umowy: "\e932";
$jcon-cirlce-plus: "\e900";
$jcon-domains: "\e907";
$jcon-password: "\e929";
$jcon-domeny: "\e92a";
$jcon-home: "\e925";
$jcon-prezent: "\e926";
$jcon-profile-male: "\e927";
$jcon-profile-female: "\e928";
$jcon-alarm: "\e905";
$jcon-todo: "\e906";
$jcon-man: "\e908";
$jcon-firms: "\e909";
$jcon-trade_firms: "\e90a";
$jcon-group: "\e90b";
$jcon-kalendarz: "\e90c";
$jcon-women: "\e90d";
$jcon-menu: "\e90e";
$jcon-services: "\e913";
$jcon-contracts: "\e91e";
$jcon-users: "\e91f";
$jcon-close: "\e920";
$jcon-tasks: "\e921";
$jcon-trade_tasks: "\e923";
$jcon-zlecone: "\e924";
$jcon-plus: "\e922";
$jcon-jellinek-path1: "\e914";
$jcon-jellinek-path2: "\e915";
$jcon-jellinek-path3: "\e916";
$jcon-jellinek-path4: "\e917";
$jcon-jellinek-path5: "\e918";
$jcon-jellinek-path6: "\e919";
$jcon-jellinek-path7: "\e91a";
$jcon-jellinek-path8: "\e91b";
$jcon-jellinek-path9: "\e91c";
$jcon-jellinek-path10: "\e91d";
$jcon-document: "\e005";
$jcon-documents: "\e006";
$jcon-search: "\e007";
$jcon-newspaper: "\e009";
$jcon-calendar: "\e00d";
$jcon-picture: "\e00f";
$jcon-pictures: "\e010";
$jcon-video: "\e011";
$jcon-camera: "\e012";
$jcon-printer: "\e013";
$jcon-briefcase: "\e015";
$jcon-wallet: "\e016";
$jcon-gift: "\e017";
$jcon-bargraph: "\e018";
$jcon-expand: "\e01a";
$jcon-focus: "\e01b";
$jcon-edit: "\e01c";
$jcon-ribbon: "\e01e";
$jcon-hourglass: "\e01f";
$jcon-lock: "\e020";
$jcon-megaphone: "\e021";
$jcon-trophy: "\e023";
$jcon-flag: "\e024";
$jcon-map: "\e025";
$jcon-puzzle: "\e026";
$jcon-basket: "\e027";
$jcon-envelope: "\e028";
$jcon-streetsign: "\e029";
$jcon-gears: "\e02b";
$jcon-key: "\e02c";
$jcon-paperclip: "\e02d";
$jcon-attachment: "\e02e";
$jcon-pricetags: "\e02f";
$jcon-lightbulb: "\e030";
$jcon-layers: "\e031";
$jcon-pencil: "\e032";
$jcon-tools: "\e033";
$jcon-tools-2: "\e034";
$jcon-magnifying-glass: "\e037";
$jcon-linegraph: "\e039";
$jcon-mic: "\e03a";
$jcon-caution: "\e03d";
$jcon-recycle: "\e03e";
$jcon-genius: "\e046";
$jcon-map-pin: "\e047";
$jcon-chat: "\e049";
$jcon-heart: "\e04a";
$jcon-target: "\e04e";
$jcon-piechart: "\e050";
$jcon-speedometer: "\e051";
$jcon-global: "\e052";
$jcon-compass: "\e053";
$jcon-clock: "\e055";
$jcon-quote: "\e057";
$jcon-scope: "\e058";
$jcon-alarmclock: "\e059";
$jcon-refresh: "\e05a";
$jcon-happy: "\e05b";
$jcon-sad: "\e05c";
$jcon-down: "\e901";
$jcon-up: "\e902";
$jcon-right: "\e903";
$jcon-left: "\e904";
$jcon-arrow-down: "\e90f";
$jcon-arrow-up: "\e910";
$jcon-arrow-right: "\e911";
$jcon-arrow-left: "\e912";

