$padding: calc(#{$grid-gutter-width} / 2);
#action-panel {
  width: 100%;
  height: 40px;
  background-color: $white;
  display: flex;
  align-items: center;
  padding-left: $padding;
  padding-right: $padding;

  .select2-container--default .select2-selection--single {
    border: none;
  }
}

#collapsed-action-panel {
  background-color: $gray-lighter;
  padding-bottom: 28px;
  padding-left: 28px;
  padding-right: 28px;
  margin-bottom: 28px;

  h5 {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  #domain-firm-name-section {
    input {
      @extend .input;
      width: 100%;
    }
  }

  #services-section {
    .input {
      border: none;
      height: auto;
    }
  }

  .input {
    width: 100%;
  }

  .date {
    width: 100%;
  }
}

#bottom-panel {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  //justify-content: flex-end;
  //padding-left: $padding;
  //padding-right: $padding;
  margin-top: $grid-gutter-width;
}

#action-panel {
  display: flex;
  //justify-content: flex-end;
  margin-bottom: 28px;
  margin-top: 5px;

  #search-container {
    display: flex;
    width: 300px;
    position: relative;

    input[type="search"] {
      width: 100%;
      height: 35px;
      margin-right: 10px;
      border: none;
      border-bottom: 1px solid $dark;
      padding-left: 5px;
      padding-right: 5px;
      font-style: italic;
    }

    #ajax-search-result-container {
      position: absolute;
      z-index: 100;
      top: 40px;
      width: 100%;

      #ajax-search-result {
        background-color: $gray-lighter;
        border: 1px solid $gray;
        padding: 10px;
        padding-top: 15px;

        a {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
}