.task-list {
  padding: calc(#{$grid-gutter-width} / 2);
  padding-bottom: 23px;

  &.no-padding {
    padding: 0;
    padding-bottom: 23px;
  }

  .title-container {
    height: 48px;

    h5 {
      margin: 0;
    }
  }

  h5 {
    margin-bottom: 23px;

    span {
      &.danger {
        color: $brand-danger;
      }

      &.warning {
        color: $brand-warning;
      }

      &.success {
        color: $brand-success;
      }
    }
  }

  @at-root {
    .tasks {
      padding-top: 23px;
      margin-left: -30px;
      margin-right: -30px;

      .task {
        display: block;
        border-left: 3px solid transparent;
        border-bottom-color: $border-dark-color;
        padding-left: 30px;
        padding-right: 70px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-bottom: 1px;
        position: relative;

        .eye {
          display: none;
          width: 32px;
          height: 32px;
          position: absolute;
          background-color: $info;
          border-radius: 50%;
          right: 30px;
          font-size: 20px;
          @include box-shadow($info);
        }

        &:hover {
          border-left-color: $info;
          background-color: $light;

          .eye {
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;

          }
        }

        &.overdue {
          padding-top: 20px;
          border-left-color: $danger;

          .eye {
            background-color: $danger;
            @include box-shadow($danger);
          }

          .title {
            color: $danger !important;
          }


        }

        .priority {
          margin-bottom: 5px;
        }

        .contact-person {
          margin-top: 10px;

          .name {
            font-weight: $font-weight-bold;
          }

          .email {
            span {
              text-decoration: underline;
            }
          }
        }

        .users {
          margin-bottom: 20px;
        }

        .teaser {
          margin-top: 10px;
          overflow: hidden;
          height: 32px;
        }
      }

      .line-separator {
        height: 1px;
        background-color: $border-dark-color;
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  }
}

#site-task-steps {
  margin-top: 40px;

  .step {
    padding: 20px;

    &.start {
      background-color: $gray-light;
    }

    &.done {
      background-color: $state-success-bg;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

#periodic-tasks-timeline {
  margin-top: $grid-gutter-width;
  padding-top: $grid-gutter-width;

  .periodic-task {
    border-left: 1px solid $border-color;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    position: relative;

    &:before {
      content: '';
      width: 10px;
      height: 1px;
      background-color: $border-color;
      position: absolute;
      top: 50%;
      left: 0;
    }

    &.done {
      background-color: $state-success-bg;
    }

    .buttons {
      margin-top: 10px;
    }

    .note {
      textarea {
        width: 100%;
        height: 100px;
      }
    }
  }
}
