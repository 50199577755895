//########## BS VARIABLES ##########

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$line-height-lg: 1.6;
$line-height-sm: 1.6;

$font-family-sans-serif: 'Muli', Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$line-height-base: 1.6;
$font-size-base: 0.95rem; // 1 rem to 16 px
$font-size-sm: .8rem;
$font-size-lg: 1.2rem;

//########## TE VARIABLES ##########
$font-folder: "../../../fonts/";
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-base: $font-weight-regular;

$font-families: (
        "Muli": (
                $font-weight-light: "Muli-Light",
                $font-weight-regular: "Muli-Regular",
                $font-weight-semibold: "Muli-Semibold",
                $font-weight-bold: "Muli-Bold"
        ),
);

// headings
$heading-font-size: (
        1: 35px,
        2: 30px,
        3: 28px,
        4: 25px,
        5: 22px,
        6: 1.1rem
);

$heading-line-height: (
        1: 1.2em,
        others: $line-height-base
);

$heading-font-weight: (
        1: $font-weight-regular,
        3: $font-weight-regular,
        6: $font-weight-regular,
        others: $font-weight-base
);

$heading-color: (
        others: $text-color,
);

$heading-font-family: (
        others: $font-family-base
);

$heading-margin-bottom: (
        1: .4em,
        2: 1em,
        3: 1em,
        5: 0.5em,
        6: 1em,
        others: $line-height-base + em
);
$heading-text-transform: (
        others: none
);
