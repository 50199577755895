@import "~tinymce/skins/lightgray/skin.min.css";
@import "~tinymce/skins/lightgray/content.min.css";

@import "colors-variables";
@import "typography-variables";
@import "bootstrap-variables";
@import "main-variables";
$icomoon-font-path: "../../fonts" !default;
@import "../jcons/variables";
@import "../jcons/style";

@import "~@ttskch/select2-bootstrap4-theme/src/layout";

@import "~bootstrap";
@import "colors";
@import "../../../node_modules/pc-bootstrap4-datetimepicker/src/sass/bootstrap-datetimepicker-build";

@import "../../../node_modules/lightbox2/src/css/lightbox.css";

@import "select2-variables";
@import "../../../node_modules/select2/src/scss/core";

@import "bootstrap-normalize";
@import "te_mixins/mixins-manifest";
@import "core/core";
@import "tinymce";
@import "btn";
@import "headings";
@import "login";

@import "base";
@import "breadcrumbs";
@import "top";
@import "left-sidebar";
@import "dashboard";

@import "common";
@import "comments";
@import "task";
@import "action-panel";
@import "birthday";
@import "note";
@import "labels";
@import "buttons";
@import "contact-person";
@import "offer-generator";
@import "form/form";
@import "form/select";
@import "alerts";
@import "~monaco-editor/dev/vs/editor/editor.main.css";
