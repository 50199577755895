//@import "select";

select {
  width: 100%;
}

input[type="text"] {
  width: 100%;

  &.te-text-input {
    background-color: transparent;
    line-height: 32px;
    border: none;
    border-bottom: 1px solid $border-dark-color;
    color: $text-color;
  }
}

input::-webkit-input-placeholder {
  color: $text-muted !important;
}

input::-moz-placeholder {
  color: $text-muted !important;
}

input:-moz-placeholder { /* Older versions of Firefox */
  color: $text-muted !important;
}

input:-ms-input-placeholder {
  color: $text-muted !important;
}